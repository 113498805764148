console.log("%c" + 'Ваш браузер – ' + navigator.sayswho, "color:forestgreen;font-size:16px;")
#console.warn('Знаешь, как сделать лучше? Напиши нам об этом. yakov@wizart.pro')

#new WOW().init()

$('#menuTrigger').on 'click', () ->
  $('#jsMainMenu').toggleClass('open')

$('#videoStart').on 'click', () ->

  $(this).closest('.video-block').addClass('show')
  iframe = $(this).closest('.video-block').find('iframe')
  iframe.attr('src', iframe.data('src') + '?' + iframe.data('settings'));




# Стилизация элементов форм
selectTextWrapper = (selectbox) ->
  text = selectbox.next().find('.jq-selectbox__select-text')
  text.html('<span>' + text.text() + '</span>')
  true

$('select:not(.unstyled)').each ->
  $this = $(this)
  $this.styler
    selectSearch: true
    selectPlaceholder: if $this.data('placeholder') then $this.data('placeholder') else '-- Выберите --'
    onFormStyled: ->
      selectTextWrapper($this)
      true
    onSelectClosed: ->
      selectTextWrapper($this)
      true


$('input[type=number]:not(.unstyled), input[type=checkbox]:not(.unstyled), input[type=radio]:not(.unstyled)').styler()

$('input[type="file"]').each ->
  $(this).styler({
    filePlaceholder: $(this).data('placeholder')
    fileBrowse: '',
  })
  true

$('input[name="phone"]').mask('+79999999999');

areaSlider = $('#areaSlider')
areaInput = $('#areaInput')
handle = $('#customHandle')

areaSlider.slider({
  range: 'min'
  value: areaSlider.data('value')
  min: areaSlider.data('min')
  max: areaSlider.data('max')
  step: areaSlider.data('step')
  create: () ->
    handle.html('<span class="handle-label">' + $(this).slider('value') + ' м<sup>2</sup></span>')
  slide: (event, ui) ->
    handle.html('<span class="handle-label">' + $(this).slider('value') + ' м<sup>2</sup></span>')
    areaInput.val(ui.value)
})

areaInput.val(areaSlider.slider('value'))

animateCss = (target, animationName, callback) ->
  animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend'
  target.addClass('animated ' + animationName).one animationEnd, ->
    $(this).removeClass('animated ' + animationName)
    callback() if callback
  this

mainSlider = $('#mainSlider')
if mainSlider.length > 0
  mainSlider.slick({
    swipe: true
    draggable: true
    dots: true
    dotsClass: 'slick-dots main-slider-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: true
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 1000
    autoplay: if typeof mainSlider.data('autoplay') == 'boolean' then mainSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 1
    slidesToScroll: 1
    responsive: [
      {
        breakpoint: 992
        settings: {
          arrows: false

        }
      }


    ]
  })

mainSlider.on 'beforeChange', (event, slick, currentSlide, nextSlide) ->

  currentImg = $(mainSlider.find('.js-img-wrap')[currentSlide])
  nextImg = $(mainSlider.find('.js-img-wrap')[nextSlide])
  animateCss(currentImg, 'fadeOut', () ->
    currentImg.removeClass('animated fadeOut')
  )
  animateCss(nextImg, 'fadeIn', () ->
    nextImg.removeClass('animated fadeIn')
  )

  currentMainText = $(mainSlider.find('.js-main-text')[currentSlide])
  nextMainText = $(mainSlider.find('.js-main-text')[nextSlide])
  animateCss(currentMainText, 'fadeOut', () ->
    currentMainText.removeClass('animated fadeOut')
  )
  animateCss(nextMainText, 'fadeInRight', () ->
    nextMainText.removeClass('animated fadeInRight')
  )

  currentDescription = $(mainSlider.find('.js-description')[currentSlide])
  nextDescription = $(mainSlider.find('.js-description')[nextSlide])
  animateCss(currentDescription, 'fadeOut', () ->
    currentDescription.removeClass('animated fadeOut')
  )
  animateCss(nextDescription, 'fadeInUp', () ->
    nextDescription.removeClass('animated fadeInUp')
  )

  currentBtn = $(mainSlider.find('.js-white-more-btn')[currentSlide])
  nextBtn = $(mainSlider.find('.js-white-more-btn')[nextSlide])
  animateCss(currentBtn, 'zoomOut', () ->
    currentBtn.removeClass('animated zoomOut')
  )
  animateCss(nextBtn, 'zoomIn', () ->
    nextBtn.removeClass('animated zoomIn')
  )

  currentLine = $(mainSlider.find('.js-line-btn-wrap')[currentSlide])
  nextLine = $(mainSlider.find('.js-line-btn-wrap')[nextSlide])
  animateCss(currentLine, 'flipOutX', () ->
    currentLine.removeClass('animated flipOutX')
  )
  animateCss(nextLine, 'flipInX', () ->
    nextLine.removeClass('animated flipInX')
  )


technoSlider = $('#technoSlider')
if technoSlider.length > 0
  technoSlider.slick({
    swipe: true
    draggable: true
    dots: true
    dotsClass: 'slick-dots techno-slider-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 1000
    autoplay: if typeof technoSlider.data('autoplay') == 'boolean' then technoSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 1
    slidesToScroll: 1
    responsive: [
      {
        breakpoint: 992
        settings: {
          arrows: false

        }
      }
      {
        breakpoint: 768
        settings: {
          dots: false
          arrows: false

        }
      }


    ]
  })

meritsSlider = $('#meritsSlider')
if meritsSlider.length > 0
  meritsSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 1000
    autoplay: if typeof meritsSlider.data('autoplay') == 'boolean' then meritsSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 5
    slidesToScroll: 1
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 3

        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 2

        }
      }


    ]
  })

historySlider = $('#historySlider')
if historySlider.length > 0 && window.innerWidth < 768
  historySlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 1000
    autoplay: if typeof historySlider.data('autoplay') == 'boolean' then historySlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToShow: 2
    slidesToScroll: 1

  })


clientsLogoSlider = $('#clientsLogoSlider')
if clientsLogoSlider.length > 0
  clientsLogoSlider.slick({
    swipe: true
    draggable: true
    dots: true
    dotsClass: 'slick-dots clients-dots list-unstyled'
    arrows: false
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof clientsLogoSlider.data('autoplay') == 'boolean' then clientsLogoSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToScroll: 1
    slidesToShow: 7
    rows: 3
    responsive: [
      {
        breakpoint: 1920
        settings: {
          slidesToShow: 5

        }
      }
      {
        breakpoint: 1600
        settings: {
          slidesToShow: 3

        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 2
          rows: 2
        }
      }

    ]

  })

articleSlider = $('#articleSlider')
if articleSlider.length > 0
  articleSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: true
    prevArrow: '<button type="button" class="article-arrow slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="article-arrow slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof articleSlider.data('autoplay') == 'boolean' then articleSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToScroll: 1
    slidesToShow: 1
    responsive: [
      {
        breakpoint: 768
        settings: 'unslick'
      }

    ]

  })


managerSlider = $('#managerSlider')
if managerSlider.length > 0
  managerSlider.slick({
    swipe: true
    draggable: true
    dots: false
    dotsClass: 'slick-dots list-unstyled'
    arrows: false
    prevArrow: '<button type="button" class="article-arrow slick-prev"><span></span></button>'
    nextArrow: '<button type="button" class="article-arrow slick-next"><span></span></button>'
    infinite: true
    pauseOnHover: false
    fade: false
    cssEase: 'ease-in-out'
    vertical: false
    centerMode: false
    centerPadding: '0px'
    speed: 750
    autoplay: if typeof managerSlider.data('autoplay') == 'boolean' then managerSlider.data('autoplay') else true
    autoplaySpeed: 5000
    slidesToScroll: 1
    slidesToShow: 3
    responsive: [
      {
        breakpoint: 992
        settings: {
          slidesToShow: 2

        }
      }
      {
        breakpoint: 768
        settings: {
          slidesToShow: 1

        }
      }
    ]

  })

accordion = $('#accordion')
accordion.titles = accordion.find('h3')
accordion.map = $('#svgMap')
accordion.map.areas = accordion.map.find('path')

accordion.titles.on 'click', ->
  $(this).addClass('active').siblings('h3').removeClass('active').next().slideUp(250)
  $(this).next().slideDown(250)
  area = $(this).data('area')
  accordion.map.find('path').removeClass('active')
  $('#' + area).addClass('active')
  true

accordion.map.areas.on 'click', ->
  area = $(this).attr 'id'
  accordion.titles.each ->
    if $(this).data('area') == area
      $(this).trigger 'click'
      return true
    true
  true


window.MapInit = ->
  MapBlock = $('#map-block')
  markers = MapBlock.find '.marker'

  if MapBlock.length > 0
    $('<div id="map"></div>').appendTo MapBlock
    $('#map').css {
      'height': parseInt(MapBlock.data 'height')
    }

    $.getScript '//api-maps.yandex.ru/2.1/?load=package.standard,package.geoObjects&lang=ru-RU', () ->
      ymaps.ready ->

# Геокодер
        crds = (crd, callback) ->
          ymaps.geocode(crd, {results: 1}).then(
            (response) ->
              callback response.geoObjects.get(0).geometry.getCoordinates()
            (error) ->
              console.log error
          )

        # Создание карты
        mapX = parseFloat(MapBlock.data 'x')
        mapY = parseFloat(MapBlock.data 'y')
        zoom = parseInt(MapBlock.data 'zoom')
        mapCenter = MapBlock.data 'center'
        center = []
        # console.log(mapX, mapY, mapAddress)

        if mapX && mapX
          center = [mapX, mapY]
        else
          center = crds mapCenter, (coord) ->
            coord
        Map = new ymaps.Map 'map', {
          center: center
          zoom: zoom
          controls: ['zoomControl']
        }
        if $('html').is '.desktop'
          Map.behaviors.disable 'scrollZoom'

        # Установка маркера
        setMarker = (coord, options) ->
          marker = new ymaps.Placemark coord, options, {
            iconLayout: 'default#image',
            hideIconOnBalloonOpen: true,
            iconImageHref: "../img/marker.svg",
            iconImageSize: [54, 68],
            iconImageOffset: [-41, -68]
          }

          Map.panTo coord, {flying: true}
          #        Map.geoObjects.removeAll()
          Map.geoObjects.add marker
          true

        # Обработка маркеров
        markers.each ->
          address = $(this).data 'address'
          title = $(this).data 'title'
          body = $(this).data 'body'
          footer = $(this).data 'footer'
          link = $(this).data 'link'
          header = '<a href="' + link + '" title="Подробнее"><span>' + title + '</span></a>'
          x = parseFloat($(this).data 'x')
          y = parseFloat($(this).data 'y')
          options = {
            hintContent: title
            balloonContentHeader: header
            balloonContentBody: body.replace(/\(M\)/g, "<span class='metro'>M</span>").replace(/\(br\)/g, "<br/>")
            balloonContentFooter: footer

          }
          # console.log(options, address, title, footer, x, y)
          if x && y
            setMarker [x, y], options
          else
            crds address, (coord) ->
              setMarker coord, options
              true
          true
        true
  true

MapInit()


window.MiniMapInit = ->
  MapBlock = $('.mini-map-block-js')


  if MapBlock.length > 0

    $.getScript '//api-maps.yandex.ru/2.1/?load=package.standard,package.geoObjects&lang=ru-RU', () ->
      ymaps.ready ->

        # Геокодер
        crds = (crd, callback) ->
          ymaps.geocode(crd, {results: 1}).then(
            (response) ->
              callback response.geoObjects.get(0).geometry.getCoordinates()
            (error) ->
              console.log error
          )

        # Установка маркера
        setMarker = (coord, options, obj) ->
          marker = new ymaps.Placemark coord, options, {
            iconLayout: 'default#image',
            hideIconOnBalloonOpen: true,
            iconImageHref: "../img/marker.svg",
            iconImageSize: [54, 68],
            iconImageOffset: [-41, -68]
          }

          obj.Map.panTo coord, {flying: true}
          # Map.geoObjects.removeAll()
          obj.Map.geoObjects.add marker
          true

        MapBlock.each (index, value)->
          $thisMap = $(this)
          $('<div id="mini-map-' + index + '"></div>').appendTo $thisMap
          $('#mini-map-' + index).css {
            'height': parseInt($thisMap.data 'height')
          }

          # Создание карты
          mapX = parseFloat($thisMap.data 'x')
          mapY = parseFloat($thisMap.data 'y')
          zoom = parseInt($thisMap.data 'zoom')
          mapCenter = $thisMap.data 'center'
          center = []
#          console.log(mapX, mapY, mapCenter)

          if mapX && mapX
            center = [mapX, mapY]
          else
            center = crds mapCenter, (coord) ->
              coord

          $thisMap.Map = new ymaps.Map ('mini-map-' + index), {
            center: center
            zoom: zoom
            controls: ['zoomControl']
          }
          if $('html').is '.desktop'
            $thisMap.Map.behaviors.disable 'scrollZoom'

          markers = $thisMap.find '.marker'

          # Обработка маркеров
          markers.each ->
            address = $(this).data 'address'
            title = $(this).data 'title'
            body = $(this).data 'body'
            footer = $(this).data 'footer'
            link = $(this).data 'link'
            header = '<a href="' + link + '" title="Подробнее"><span>' + title + '</span></a>'
            x = parseFloat($(this).data 'x')
            y = parseFloat($(this).data 'y')
            options = {
              hintContent: title
              balloonContentHeader: header
              balloonContentBody: body.replace(/\(M\)/g, "<span class='metro'>M</span>").replace(/\(br\)/g, "<br/>")
              balloonContentFooter: footer

            }
            # console.log(options, address, title, footer, x, y)
            if x && y
              setMarker [x, y], options, $thisMap
            else
              crds address, (coord) ->
                setMarker coord, options, $thisMap
                true
            true
          true


MiniMapInit()
